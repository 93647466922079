import React from 'react'
import {Route, Routes} from 'react-router-dom'
import ULink from "./uLink"
import './App.css'
import {urlAlert} from "api-methods"

function Root() {

    if (document.location.pathname.replace(/[/_.A-Za-z0-9-]+/g, "") !== "") {urlAlert('url-pathname-char').then()}
    if (document.location.search.replace(/[?=&A-Za-z0-9]+/g, "") !== "") {urlAlert('url-search-char').then()}

    return <Routes>
        <Route path="/*" element={<ULink />} />
    </Routes>
}

export default Root
