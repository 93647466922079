import React, {useState} from "react"
import {apiLinkGuid, apiLinkGuidCheckMb} from "api-methods"
import {urlGuidSearch} from 'helper-func'

function App() {///installation?external_partner_id=123&signature=xxxxxx

    const [popup, setPopup] = useState(false)//false
    const [apiKey, setApiKey] = useState("")//A1A2A3A4 - A1A2A3A4 - A1A2A3A4 - A1A2A3A4
    const [copyPush, setCopyPush] = useState('')
    const [pLoad, setPLoad] = useState(false)
    const [pError, setPError] = useState("Не удалось получить ключ")

    function copyTextFromInput(text) {if (copyPush === "") {navigator.clipboard.writeText(text);copyKeyPush()}}

    function copyKeyPush () {setTimeout(() => {setCopyPush('')}, 2000)}

    async function getCode (value = true) {
        setPopup(value)
        if (apiKey === "") {
            setPLoad(true)
            await apiLinkGuid(urlGuidSearch(1)).then(item => {
                if (item.code === 0) {
                    apiLinkGuidCheckMb(item)
                    if (item.data && item.data.keyList && item.data.keyList[0] && item.data.keyList[0].value) {
                        setApiKey(item.data.keyList[0].value)
                    }
                } else {
                    if (item.message) {
                        setPError(item.message)
                    }
                }
                setPLoad(false)
            })
        }
    }

    return (
        <div className="App">

            {popup && <div id="popup" onClick={e => setPopup(false)} />}

            {popup && <div id="key">
                <div><div onClick={e => getCode(false)} /></div>
                <div>Activation code for the Premium version of Kaspersky Safe Kids</div>
                {
                    (pLoad)
                        ? <div><div><div className="layerLoader"><div className="preLoader"><div className="loader" /></div></div></div></div>
                        : (
                            (apiKey === "")
                                ? <div id="key_err">{pError}</div>
                                : <div><div><div>{apiKey}</div><div onClick={() => copyTextFromInput(apiKey)} /></div></div>
                        )
                }
            </div>}

            <div id="body" className="">

                <div id="head">
                    <div/><div><div>Kaspersky Safe Kids</div><div>The all-in-one app for digital parenting and family well being</div></div>
                </div>

                <div id="show">
                    <div><div><div/></div><div>Monitor</div><div>Know where your kids are 24/7, monitor their digital activity and screen time, and get alerts for worrying behavior.</div></div>
                    <div><div><div/></div><div>Protect</div><div>Protect your children from negative experiences across devices by blocking harmful content.</div></div>
                    <div><div><div/></div><div>Educate</div><div>Nurture positive habits by teaching digital safety and encouraging online-offline balance.</div></div>
                </div>

                <div id="name">
                    <div>Setting up Kaspersky Safe Kids is easy</div>
                    <div>Follow these steps to install the app or use parental controls directly on <a target="_blank" href="https://my.kaspersky.com">My Kaspersky</a></div>
                </div>

                <div id="step1">
                    <div className="">
                        <div />
                        <div>From your mobile, download Kaspersky Safe Kids from the relevant mobile store using the buttons below. Select “Parent” mode when prompted. This will let you manage settings on the go.</div>
                        <div><a target="_blank" href="https://play.google.com/store/apps/details?id=com.kaspersky.safekids" /><a target="_blank" href="https://apps.apple.com/us/app/kaspersky-safe-kids-with-gps/id967986300" /></div>
                    </div>
                    <div><div /></div>
                </div>

                <div id="step2">
                    <div><div /></div>
                    <div className="">
                        <div />
                        <div>During installation, create a <a target="_blank" href="https://my.kaspersky.com">My Kaspersky</a> account or use the one you have already. You only need one account to cover all of your children’s devices.</div>
                    </div>
                </div>

                <div id="step3">
                    <div className="">
                        <div />
                        <div>To download the app on any of your children’s devices, open this link: <a href="">kas.pr/kids</a> or click the relevant button below from child’s device. Once installed, select “Child” mode.</div>
                        <div><a target="_blank" href="https://play.google.com/store/apps/details?id=com.kaspersky.safekids" /><a target="_blank" href="https://apps.apple.com/us/app/kaspersky-safe-kids-with-gps/id967986300" /></div>
                    </div>
                    <div><div /><div /></div>
                </div>

                <div id="how">
                    <div>How to activate the Premium version</div>
                    <div onClick={e => getCode(true)}>Get the Activation code</div>
                </div>

                <div id="steps" className="">
                    <div><div /><div>At <a target="_blank" href="https://my.kaspersky.com">My Kaspersky</a>, go to ‘Subscriptions’ page.</div></div>
                    <div><div /><div>In section ‘If you have an activation code’ enter your Activation code and tap the ‘Add’ button.</div></div>
                    <div><div /><div>The app may also be activated via the Android app in parent mode. Go to ‘Additional’ section, select ‘Activation code’ and enter the code.</div></div>
                </div>

                <div id="helps">
                    <div id="help" className="">
                        <div />
                        <div>Support</div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default App
